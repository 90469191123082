<template>
  <section>
    <pm-Breadcrumb :home="home" :model="itemsBread" />

    <div class="col-md-12 alinhamento">
      <div class="row">
        <div class="col-md-8">
          <h2 class="titulo-links-escolha">Formulários da Creche</h2>
        </div>
      </div>

      <painelFiltro
        modelo="0"
        individual="0"
        :objeto="false"
        @buscarRelatorio="buscarRelatorio"
        :filtro="filtro"
      />
      <section v-if="info.turma_id">
        <div class="grid card-filtro alinhameneto-margem">

          <div class="col-12 sm:col-12 md:col-3 lg:col-2 xl:col-2">
            <label>Escolha o Formulário:</label>
            <select
              class="p-inputtext p-component select"
              v-model="selectedFormulario"
              @change="getPerguntasCreche"
            >
              <option
                v-for="a in tipoPerguntas"
                :key="a.id"
                :value="a"
                :selected="selectedFormulario"
              >
                {{ a.nome }}
              </option>
            </select>
          </div>

          <!-- <div class="grid align-items-end ml-1 mb-2 pl-1">
            <button
              class="btn btn-primary pt-1 pb-1"
              @click="getPerguntasCreche()"
            >
              Pesquisar
            </button>
          </div> -->
        </div>

        <button
          class="btn btn-danger button-generate"
          @click="pdf()"
          title="Gerar Impressão"
        >
          <i class="fa fa-file-pdf-o"></i> Pdf
        </button>

        <button  class="btn btn-success" @click="gerarExcel()" style="margin-top:10px;"
          title="Gerar Excel">
          <i class="fa fa-file-excel-o"></i> Excel
        </button>

        <button class="btn btn-secondary ml-1" @click="gerarCsv('dadosrel')" style="margin-top:10px;"

          title="Gerar csv">
          <i class="fa fa-file-o"></i> CSV
        </button>
        <div
          class="mt-3 row"
          id="dadosrel"

        >
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
            <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
            <meta charset="utf-8">
          </head>
          <br>

          <div class="grid justify-content-between align-items-center col-12" v-if="cabecalho">
            <div class="col-5 align-content-center">
              <img
                style="width: auto; height: 100px;"
                :src="base_url + '/storage/' + cabecalho.logo"
                alt=""
                class="img"
              />
            </div>

            <div
              style="margin: 0px !important;"
              v-html="cabecalho.texto_logo"
              class="cabecalho font-cabecalho mr-5"
            ></div>
          </div>

          <div >
            <table class="col-12 mt-2 borda-externa" style="min-width: 1500px;">
              <thead class="cor-titulo">
                <tr align="center">
                  <th colspan="3">{{selectedFormulario.nome}} </th>
                </tr>
              </thead>
              <tbody class="borda-externa">

                <tr>
                  <td class="">Unidade Escolar: {{ infoTurma.escola.nome }}</td>
                  <td class="">Turno: {{ infoTurma.turno }}</td>
                </tr>

                <tr>
                  <td class="borda-">Turma: {{ infoTurma.nome }}</td>
                  <td class="borda-">Ano Letivo: {{ infoTurma.ano }}</td>
                </tr>

              </tbody>
            </table>

            <table v-for="(pergunta, index) in perguntas" :key="index" class="table" border="1">
              <thead class=" borda-externa">
                <tr align="center" class="borda-right cor-titulo">
                  <th colspan="5">{{pergunta.pergunta}} </th>
                </tr>
                <tr align="center">
                  <th colspan="1" class="borda-right">Alunos</th>
                  <th colspan="1" class="borda-right" v-for="(periodo, index) in periodos" :key="index">{{periodo.nome}} </th>
                </tr>
              </thead>

              <tbody v-if="selectedFormulario.sigla === 'FA'" class="borda-externa ">
                <tr v-for="(aluno, index) in alunos" :key="index">
                  <td class="borda-right" >{{ aluno.nome }} {{ aluno.sobrenome }}</td>
                  <td style="text-align: center;" v-for="(periodo, indexPeriodo) in periodos" :key="indexPeriodo" class="borda-right">
                    <span v-if="respostas && respostas[pergunta.id] && respostas[pergunta.id][aluno.id] && respostas[pergunta.id][aluno.id][periodo.id]">
                      <span v-if="respostas[pergunta.id][aluno.id][periodo.id].resposta_objetiva === 1">S</span>
                      <span v-else-if="respostas[pergunta.id][aluno.id][periodo.id].resposta_objetiva === 0">N</span>
                      <span v-else-if="respostas[pergunta.id][aluno.id][periodo.id].resposta_objetiva === 3">CD</span>
                      <span v-else-if="respostas[pergunta.id][aluno.id][periodo.id].resposta_objetiva === 4">ED</span>
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                </tr>
                <tr align="center">
                  <th colspan="12" class=" borda-externa">
                    Legenda: S(Sim), N(Não), CD(Com Dificuldade), ED(Em Desenvolvimento)
                  </th>
                </tr>
              </tbody>
              <tbody v-if="selectedFormulario.sigla === 'AD'" class="borda-externa ">
                <tr v-for="(aluno, index) in alunos" :key="index">
                  <td class="borda-right" style="vertical-align: middle">{{ aluno.nome }} {{ aluno.sobrenome }}</td>
                  <td style="text-align: center; max-width: 183px" v-for="(periodo, indexPeriodo) in periodos" :key="indexPeriodo" class="borda-right">
                    <span v-if="respostas && respostas[pergunta.id] && respostas[pergunta.id][aluno.id] && respostas[pergunta.id][aluno.id][periodo.id]">
                      <span >{{ respostas[pergunta.id][aluno.id][periodo.id].resposta_escolha }}</span>
                    </span>
                    <span v-else>
                      -
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="col-12 borda-externa">
              <tbody>


                <tr align="center">
                  <td>___________________</td>
                  <td>___________________</td>
                  <td>___________________</td>
                </tr>
                <tr align="center">
                  <td>Professor (a)</td>
                  <td>Professor (a)</td>
                  <td>Professor (a)</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import painelFiltro from "./components/filtros/painelFiltro.vue";
import { PerguntasCreche } from "@/pages/admin/pages/relatorios/services/perguntasCreche.service";
import { TiposPerguntas } from "@/utils/tiposPerguntas";

export default defineComponent({
  components: { painelFiltro },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),

      home: { icon: "pi pi-home", to: "/relatorios/conteudos-aplicados" },
      itemsBread: [{ label: "Formulários da Creche" }],
      info: {},
      prevRoute: null,
      infoTurma: [],
      tipoPerguntas: TiposPerguntas,
      alunos: null,
      perguntas: null,
      respostas: null,
      selectedFormulario: {},

      indexToAlunoIdMap: null,
      periodos: [
        {id: 0, nome: '1° periodo'},
        {id: 1, nome: '2° periodo'},
        {id: 2, nome: '3° periodo'},
        {id: 3, nome: '4° periodo'},
      ],
    };
  },
  methods: {
    gerarCsv(id) {
      // Obtém a div pelo id
      const div = document.getElementById(id);
      if (!div) {
        console.error('Div não encontrada: ' + id);
        return;
      }

      // Obtém todas as linhas da div
      const linhas = div.querySelectorAll('tr');

      // Cria o conteúdo do CSV
      let csv = '';
      linhas.forEach((linha) => {
        const celulas = linha.querySelectorAll('td');
        const valores = Array.from(celulas).map((celula) => celula.textContent);
        csv += valores.join(',');
        csv += '\n';
      });

      // Cria um Blob com o conteúdo do CSV
      const csvString = "\uFEFF" + csv;
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

      // Cria uma URL para o Blob
      const url = URL.createObjectURL(blob);

      // Cria um link para download do arquivo CSV
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PerguntasCreche.csv');

      // Adiciona o link ao corpo do documento e inicia o download
      document.body.appendChild(link);
      link.click();

      // Remove o link do corpo do documento
      document.body.removeChild(link);
    },
    gerarExcel(){
      const div = document.getElementById('dadosrel');
      const divp = document.getElementById("printme");

      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      const a = document.createElement('a');
      const data_type = 'data:application/vnd.ms-excel';
      const table_html = divp.outerHTML.replace(/ /g, '%20');

      a.href = data_type + ', ' + table_html;
      a.download = 'PerguntasCreche.xls';
      a.click();
    },
    pdf() {
      const div = document.getElementById("dadosrel");
      const divp = document.getElementById("printme");
      if (div != null) {
        divp.innerHTML = div.innerHTML;
      }

      window.print();
    },

    async getPerguntasCreche() {
      let data
      try {
        data = await PerguntasCreche.getPerguntasCreche(
          this.info.ano,
          this.info.turma_id,
          this.selectedFormulario.sigla,
        );
        if (data.status === 200) {
          this.alunos = data.data.alunos;
          this.perguntas = data.data.perguntas;
          this.respostas = data.data.respostas_por_pergunta;
          this.infoTurma = data.data.turma;

          console.log('respostas',this.respostas);
        } else if (data.status === 204) {
          this.alunos = [];
          this.$vaToast.init({
            message: "Ocorreu um erro",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }

      // this.todosMeses = data.data.todos_meses;
      // if(data.data.todos_meses === 0){
      //   this.conteudosAplicados.sort((a, b) => a.dia - b.dia);
      // }
      // else{
      //   for (let i = 1; i < this.conteudosAplicados[0].length; i++) {
      //     this.conteudosAplicados[0][i].sort((a, b) => a.dia - b.dia);
      //   }
      // }
    },

    async buscarRelatorio(info) {
      this.info = info;
      // await this.getDisciplinas();
      console.log("this.selectedFormulario",this.selectedFormulario)
      await this.getPerguntasCreche();
    },

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      const divAta = document.getElementById("print");

      if ((vm.prevRoute.name === "atas" || vm.prevRoute.name === "ataAlunos") && divAta) {
        location.reload();
      }
    })
  },
  beforeMount() {
    document.getElementById('printme').innerHTML = '';
    this.selectedFormulario = this.tipoPerguntas[0];
  },
});
</script>

<style scoped>
.borda-externa {
  border: 2px #000 solid;
}
.borda-right {
  border-right: 2px #000 solid;
  /* width: 50%; */
}
.borda-right-1px {
  border-right: 1px solid #000;
}
.borda-bottom {
  border-bottom: 2px #000 solid;
}
.borda-bottom-1px {
  border-bottom: 1px solid #000;
}
.borda-top-1px {
  border-top: 1px #000 solid;
}
.borda-top {
  border-top: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}
.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}
.cor-titulo {
  background-color: #292828 !important;
  color: #fff;
}

.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>

<style>


.border{
      border: 1px solid black;
}

.header{
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}

#noprint {
  display: block;
}
#printme {
  display: none;
}

@media print {
  #noprint {
    display: none;
  }
  #printme {
    display: block;
  }
}
</style>
